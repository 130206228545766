import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/romermaldonado/Practiceshop/aguaman/aguaman-landing-v4/src/components/BlogLayout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`¿Qué es aguaman?`}</h1>
    <p>{`Aguaman es un servicio que `}<strong>{`entrega botellones de agua a domicilio`}</strong>{`. Esta idea surge como una solución al problema de tener que ir a buscar el botellón de agua al supermercado, centro de recarga, bodegón, etc. El hecho de pensar cargar un botellón de 19 litros (20 kg aproximadamente) ya causa frustración para algunos (la mayoría), para otros puede ser una excusa y así ejercitar su cuerpo.`}</p>
    <div className="div-mdx-center">
    <img src="https://image.freepik.com/foto-gratis/repartidor-cansado-botella-agua-espacio-copia_23-2148382514.jpg" alt="cansado de cargar botellones" />
    </div>
    <p>{`Las ideas `}<strong>{`surgen ante la necesidad de solucionar un problema`}</strong>{`, éste es el caso de Aguaman, salir de casa frecuentemente a buscar el botellón de agua genera una perdida de tiempo que puede ser utilizado para otro tipo de cosas, además que hoy en día el tiempo es escaso para todos. Dejando a un lado el tiempo perdido, el combustible que se gasta, el esfuerzo para cargar los pesados botellones o el sol inclemente de las 12pm (hora donde siempre se acaba el agua) encontramos la oportunidad de crear un `}<strong>{`servicio que genere comodidad`}</strong>{`.`}</p>
    <br />
    <h2>¿Cómo funciona?</h2>
    <p>{`Aguaman funciona a través de una aplicación móvil, donde puedes pedir las recargas de botellón que necesites. La aplicación está disponible para los teléfonos Android y iPhone. Descargala gratis desde `}<Link to="/descargar" mdxType="Link">{`aquí`}</Link>{`.`}</p>
    <div className="div-mdx-center">
    <blockquote>
        📱 Pides tu recarga, llevamos el botellón lleno hasta tu domicilio y nos entregas el vacio
        en buen estado ✅
    </blockquote>
    </div>
    <p>{`🚚 Puedes hacer los pedidos entre `}<strong>{`08:00am y 05:00pm de Lunes a Viernes`}</strong>{`. Los sábados se entregan desde las 08:00am hasta 01:00pm. Los domingos no se entregan botellones, pero si puedes hacer tu pedido para que sea entregado el Lunes a primera hora.`}</p>
    <p>{`💸 Diferentes `}<strong>{`métodos de pago`}</strong>{`: Pesos (COP) en efectivo, Dólares (USD) en efectivo, Pago móvil y Reserve.`}</p>
    <p>{`⏰ Puedes `}<strong>{`elegir la hora de entrega`}</strong>{` para recibir el pedido. Esto es útil cuando quieres que te entreguemos el botellón en una hora específica.`}</p>
    <br />
    <h2>¿Dónde trabajan?</h2>
    <div className="div-mdx-center">
    <img src="https://i1.wp.com/soytecno.com/wp-content/uploads/2017/07/area-01-e1501620926535.png?fit=1200%2C809&ssl=1" alt="direccion de domicilio" />
    </div>
    <p>{`Actualmente las entregas se hacen en dos localidades del Estado Táchira: San Cristóbal y San Juan de Colón`}</p>
    <p>{`Puedes ver el listado de los sectores donde realizamos entregas `}<Link to="/ciudades" mdxType="Link">{`aquí`}</Link>{`.`}</p>
    <p>{`Aguaman con ayuda de la tecnología ofrece un servicio que brinda una experiencia satisfactoria a las personas. La experiencia empieza desde que solicitas el botellón a través de nuestra aplicación móvil hasta que lo recibes en la puerta de tu casa.`}</p>
    <br />
    <p>{`¿Te gustó este articulo? Comparte este artículo para que más personas conozcan de nuestro servicio y
podamos llegar a más lugares.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      